export default defineAppConfig({
  rma: {
    name: 'HOME',
    author: {
      name: 'Zentatec GmbH'
    },
    layout: {
      footer: {
        year: new Date().getFullYear()
      }
    }
  }
})
